import React, { useEffect, useState } from 'react';


import Parse from 'parse';

// Parse.initialize("OqdevUYl2DRUVT9lRsK0kwwCpuca0hevosbk86G9", "Yy0OHh6DvfBU7gVg5DEMI8yzMTOv272kYYgXQnUN"); //PASTE HERE YOUR Back4App APPLICATION ID AND YOUR JavaScript KEY
// Parse.serverURL = 'https://parseapi.back4app.com/'

const url = "https://argentinastream.com/video/classfm.html";

const VideoPlugin = () => {

    const [link, setLink] = useState(new Parse.Object('Link'));

    async function addLink() {
        try {
            //create a new Parse Object instance
            const newLink = new Parse.Object('Link');
            //define the attributes you want for your Object
            newLink.set('Link', 'https://argentinastream.com/video/classfm.html');
            //save it on Back4App Data Store
            await newLink.save();
            console.log('Success');
        } catch (error) {
            console.log('Error saving new link: ', error);
        }
    }

    async function fetchLink() {
        //create your Parse Query using the Person Class you've created
        let query = new Parse.Query('Link');
        //run the query to retrieve all objects on Person class, optionally you can add your filters
        let queryResult = await query.find();
        //the resul is an arry of objects. Pick the first result 
        const currentLink = queryResult[queryResult.length-1];//last link updated
        // access the Parse Object attributes
        setLink(currentLink);
    }

    useEffect(() => {
        fetchLink()
    }, []);

    const style = {
        width: "95%",
        height: "60.8%",
        maxWidth: "60em",
        margin: "auto auto",
    };



    return (
        <>
            <iframe style={style} src={link.get('Link')}>
            </iframe>
        </>
    );

};
export default VideoPlugin;