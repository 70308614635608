import React from 'react';


import Parse from 'parse';

const Admin = () => {

    async function addLink() {
        const url = document.getElementById('id_link').value+"";

        if(url==="")
            return;
        
        try {
            //create a new Parse Object instance
            const newLink = new Parse.Object('Link');
            //define the attributes you want for your Object
            const url = document.getElementById('id_link').value+"";
            newLink.set('Link', url);
            //save it on Back4App Data Store
            await newLink.save();
            console.log('Success');
        } catch (error) {
            console.log('Error saving new link: ', error);
        }
    }

    const style = {
        width: "95%",
        height: "60.8%",
        maxWidth: "60em",
        margin: "auto auto",
    };



    return (
        <form onSubmit={(e)=>{e.preventDefault(); addLink()}}>
            <label>
                Link:
                <input id="id_link" type="text"/>
            </label>
            <input type="submit" value="Cambiar"/>
        </form>
    );

};
export default Admin;