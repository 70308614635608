import './Footer.css';

import logo from '../img/DTP.png'
import React from "react";

function Footer(){

    return (
        <nav className='Footer'>
          <img alt='img' src={logo}/>
        </nav>
      );
}
export default Footer;