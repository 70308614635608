import './App.css';
import { Outlet, Route, Routes } from 'react-router-dom'

import Landing from './Components/Landing.js';
import Admin from './Components/Admin.js'
import Footer from './Components/Footer.js'
import VideoPlugin from './Components/VideoPlugin';

// In a React Native application
import Parse from 'parse';
import AsyncStorage from '@react-native-async-storage/async-storage';


//Initializing the SDK
Parse.setAsyncStorage(AsyncStorage);
//Paste below the Back4App Application ID AND the JavaScript KEY
Parse.initialize("OqdevUYl2DRUVT9lRsK0kwwCpuca0hevosbk86G9", "Yy0OHh6DvfBU7gVg5DEMI8yzMTOv272kYYgXQnUN");
//Point to Back4App Parse API address 
Parse.serverURL = 'https://parseapi.back4app.com/';


function App() {
  const styles = {
    background: 'linear-gradient(to bottom, #3498db, #87CEEB)',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    color: 'white',
  };

  return (
    <div style={styles}>
      <Landing></Landing>
      <Routes>
        <Route path='/' element=<VideoPlugin/>></Route>
        <Route path='/mudochecholandia33' element=<Admin/>></Route>
      </Routes>
      <Footer></Footer>
    </div>

  );
}

export default App;