import React from 'react';
import './Landing.css';
import logo from '../img/DTP.png'


function Nav() {
    
  
    return (
      <nav className='Nav'>
        <img alt='img' src={logo}/>
        <h1>DTP</h1>
      </nav>
    );
}
function Description(){
  const styles = {
    margin: "auto",
    marginTop:"5em",
    justifyContent: "center",
  };

    return (
        <div style={styles}>
          <h2>DTP: De todo un poco</h2>
          <p>Programa todos los jueves de 20hs a 22hs.</p>
        </div>
      );
}
function Landing(){
    return(
        <>
            <Nav></Nav>
            <Description></Description>
        </>
    )
}
  
export default Landing;


